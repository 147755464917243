import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/main.less'
import '@/assets/fonts/font.css'
//抽屉
import boxTitle from '@/components/boxTitle'
//1. 引入
import router from './router'
createApp(App).component('boxTitle',boxTitle).use(router).use(ElementPlus).mount('#app')
