<template>
  <div>
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
   
  },
  mounted() {
    document.addEventListener('contextmenu', (event)=>{
      event.preventDefault();
    });
    document.addEventListener('keydown', (event)=>{
      if (event.key === "F12") {
        event.preventDefault();
      }
    });
  },
}
</script>

<style lang='less'>
body{
  padding: 0;
  margin: 0;
  text-align: justify;
  // overflow-x: hidden;
}
* {
  -webkit-user-select: none; /* 针对 WebKit 内核的浏览器 */
  -moz-user-select: none; /* 针对 Firefox 浏览器 */
  -ms-user-select: none; /* 针对 IE 浏览器 */
  user-select: none; /* 标准属性 */
}
</style>
